import request from "../utils/request";

//目录 /api/index/getCate
export const getCategory = () => {
  return request("/api/index/getCate", "post");
};

//展示 /api/index/getInfo
export const getInfo = (data) => {
  return request("/api/index/getInfo", "post", data);
};
