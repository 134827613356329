import Vue from "vue";
import VueRouter from "vue-router";
import HeaderView from "../components/HeaderView.vue";
import TopView from "../components/TopView.vue";
import MainView from "../components/MainView.vue";
// import ContView from "../components/ContView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    components: {
      default: HeaderView,
      TopView: TopView,
      MainView: MainView,
    },
  },
  // {
  //   path: "/contect",
  //   name: "contect",
  //   component: ContView,
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
  //   },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
