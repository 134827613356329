<template>
  <div class="cont_box flex">
    <div class="item_box" v-for="it in list" :key="it.id" @click="toOpen(it)">
      <img :src="it.images" alt="" class="list_img" />
      <div class="item_boxs flexA flexJ">
        <div class="title_box">{{ it.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api";

export default {
  data() {
    return {
      id: 0,
      list: [],
    };
  },
  methods: {
    toOpen(it) {
      window.open(`http://${it.url}`, "_blank");
    },
    async getInfo() {
      try {
        let data = {
          id: this.id,
        };
        const res = await getInfo(data);
        this.list = res.data[0];
        console.log("getInfo", res);
      } catch (err) {
        console.log("getInfo", err);
      }
    },
  },
};
</script>

<style>
.cont_box {
  width: 982px;
  padding: 20px 26px 30px;
  box-sizing: border-box;
  background-color: #fff;
  flex-wrap: wrap;
}
.item_box {
  width: 300px;
  height: 226px;
  margin: 0 4px 20px;
}
.item_boxs {
  width: 100%;
  padding: 18px 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px 0 rgba(207, 156, 81, 0.1);
}
.list_img {
  width: 302px;
  height: 170px;
  opacity: 1;
}
.title_box {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
  font-weight: 400;
}
.botton_box {
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 400;
  width: 48px;
  height: 24px;
  opacity: 1;
  border: 0 solid rgba(0, 117, 249, 1);
  background: rgba(252, 67, 56, 1);
}
</style>
