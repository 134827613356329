<template>
  <div class="big_box">
    <!-- 面包屑 -->
    <div class="crumbs_box flexA">
      <div>大赛</div>
      <img src="../assets/ic_right.png" alt="" class="ic_right" />
      <div>详情</div>
    </div>
    <!-- 顶部内容 -->
    <div class="flex">
      <div class="topCont_left">
        <div class="topCont_left_title">大赛名称</div>
        <div class="topCont_cont">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan
          et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis
          natoque penatibus et magnis dis parturient montes, nascetur ridiculus
          mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus
          mollis orci, sed rhoncus pronin sapien nunc accuan eget.
        </div>
        <div class="deadline">报名截止时间：2023-02-01</div>
      </div>
      <div class="topCont_right flexC">
        <div class="surplus">剩余</div>
        <div class="surplus_time"><span>17</span> 天 <span>14</span> 小时</div>
        <div class="signUpNow flexC flexA">
          <div>立即报名</div>
          <img src="../assets/ic_right_white.png" alt="" class="ic_right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.big_box {
  margin: 50px auto 0;
  width: 1200px;
  height: 278px;
  opacity: 1;
}
.ic_right {
  width: 17px;
  height: 17px;
}
.crumbs_box {
  margin-bottom: 30px;
}
.topCont_left {
  width: 969px;
  height: 231px;
  background: rgba(255, 255, 255, 1);
  padding: 30px 40px;
  box-sizing: border-box;
}
.topCont_left_title {
  color: rgba(0, 0, 0, 0.9);
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.topCont_cont {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}
.deadline {
  color: rgba(207, 156, 81, 1);
  font-size: 14px;
  font-weight: 400;
}
.topCont_right {
  flex-direction: column;
  width: 231px;
  height: 231px;
  opacity: 1;
  background: rgba(207, 156, 81, 1);
  .surplus {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 400;
  }
  .surplus_time {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 400;
    span {
      color: rgba(255, 255, 255, 1);
      font-size: 40px;
      font-weight: DIN-Bold;
    }
  }
  .signUpNow {
    margin-top: 30px;
    width: 122px;
    height: 40px;
    opacity: 1;
    border: 2px solid rgba(255, 255, 255, 1);
    div {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-weight: 400;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
