<template>
  <div>
    <div class="header_box flex">
      <!-- logo -->
      <div class="flexA">
        <img src="../assets/logo.png" alt="" class="logo_img" />
        <div>
          <div class="logo_title_top">金融街教育</div>
          <div class="logo_title_btm">Financial street</div>
        </div>
      </div>
      <!-- 顶部中间 -->
      <div class="flexA">
        <div class="items" v-for="(it, idx) in list" :key="idx">{{ it }}</div>
      </div>
      <!-- 登录 -->
      <div class="flexA">
        <img src="../assets/logo.png" alt="" class="login_img" />
        <div class="login">注册/登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: ["首页", "首页", "首页", "首页", "首页", "首页", "首页"],
    };
  },
};
</script>

<style lang="scss">
.header_box {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  justify-content: space-around;
  background-color: #646464;
  height: 50px;
}
.items {
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 400;
  margin: 0 40px;
}
.login {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
}
.logo_img {
  width: 31px;
  height: 31px;
  opacity: 1;
  margin-right: 7px;
}

.logo_title_top {
  color: rgba(207, 156, 81, 1);
  font-size: 16px;
  font-weight: W6;
}

.logo_title_btm {
  color: rgba(207, 156, 81, 1);
  font-size: 10px;
  font-weight: W3;
}
.login_img {
  width: 26px;
  height: 26px;
  opacity: 1;
  margin-right: 12px;
}
</style>
